import revive_payload_client_S5yBD7QudL from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.17.0_jiti@2.4._dd76c055ccea1cd0d340875e3818a712/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ESiXHjPQtL from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.17.0_jiti@2.4._dd76c055ccea1cd0d340875e3818a712/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_uNS8kcrc5L from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.17.0_jiti@2.4._dd76c055ccea1cd0d340875e3818a712/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_CqvxKMNuCl from "/app/node_modules/.pnpm/nuxt-site-config@3.1.0_magicast@0.3.5_vue@3.5.13_typescript@5.7.3_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_VHRJ5eLiWP from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.17.0_jiti@2.4._dd76c055ccea1cd0d340875e3818a712/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_al634Rt2Vl from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.17.0_jiti@2.4._dd76c055ccea1cd0d340875e3818a712/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_cJf05WVyKM from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.17.0_jiti@2.4._dd76c055ccea1cd0d340875e3818a712/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_n7US46puMX from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.17.0_jiti@2.4._dd76c055ccea1cd0d340875e3818a712/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_yPyIee4oy6 from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.17.0_jiti@2.4._dd76c055ccea1cd0d340875e3818a712/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_wy0B721ODc from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import siteConfig_HUj8dFJ380 from "/app/node_modules/.pnpm/nuxt-seo-utils@6.0.12_magicast@0.3.5_rollup@4.30.1_vue@3.5.13_typescript@5.7.3_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_DJS33r7qts from "/app/node_modules/.pnpm/nuxt-seo-utils@6.0.12_magicast@0.3.5_rollup@4.30.1_vue@3.5.13_typescript@5.7.3_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_eXa1MsDELu from "/app/node_modules/.pnpm/nuxt-seo-utils@6.0.12_magicast@0.3.5_rollup@4.30.1_vue@3.5.13_typescript@5.7.3_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import plugin_N7gNWcaJR2 from "/app/node_modules/.pnpm/@storyblok+nuxt@6.2.2_vue@3.5.13_typescript@5.7.3_/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import defaults_OzFN7R6F6r from "/app/node_modules/.pnpm/nuxt-seo-utils@6.0.12_magicast@0.3.5_rollup@4.30.1_vue@3.5.13_typescript@5.7.3_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaults.js";
export default [
  revive_payload_client_S5yBD7QudL,
  unhead_ESiXHjPQtL,
  router_uNS8kcrc5L,
  _0_siteConfig_CqvxKMNuCl,
  payload_client_VHRJ5eLiWP,
  navigation_repaint_client_al634Rt2Vl,
  check_outdated_build_client_cJf05WVyKM,
  chunk_reload_client_n7US46puMX,
  components_plugin_KR1HBZs4kY,
  prefetch_client_yPyIee4oy6,
  plugin_wy0B721ODc,
  siteConfig_HUj8dFJ380,
  inferSeoMetaPlugin_DJS33r7qts,
  titles_eXa1MsDELu,
  plugin_N7gNWcaJR2,
  defaults_OzFN7R6F6r
]